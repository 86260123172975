:root {
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: #242424;
  background-color: #fff;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  background-color: #fff !important;
}
a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  width: 100%;
  margin: 0;
  display: flex;
  place-items: center;
  min-height: 100vh;
  overflow-x: hidden;
}

h1 {
  font-size: 1.5em;
  line-height: 1.1;
}

#submit-btn {
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 0.5em 1rem;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: 0.5s ease;
  background-color: rgba(129, 140, 248);
  color: white;
}
#submit-btn:hover,
#submit-btn:focus {
  outline: none;
  color: black;
  border-color: rgba(129, 140, 248);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgb(129 140 248 / 30%);
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #dfdfdf;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
}

.input {
  width: 100%;
  box-sizing: border-box;
  height: 45px;
  line-height: 30px;
  padding-left: 1rem;
  border: 2px solid rgba(129, 140, 248);
  border-radius: 10px;
  outline: none;
  background-color: #f5faff;
  color: #0d0c22;
  transition: 0.5s ease;
}

.input::placeholder {
  color: #8698b1;
}

.input:focus,
.input:hover {
  outline: none;
  border-color: rgba(129, 140, 248);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgb(129 140 248 / 30%);
}
.main {
  width: 350px;
  padding: 1rem 2rem 2.5rem 2rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 1rem;
  text-align: left;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}
.form p {
  position: absolute;
  top: 2.5rem;
  font-size: 12px;
  color: red;
}
@media screen and (max-width: 768px) {
  #root,
  html,
  body {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
  }
  #root {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  body {
    min-height: 90vh !important;
  }
  .main {
    width: 70%;
    margin: auto;
  }
  .input {
    width: unset;
    padding-left: 10px;
  }
}

.email-not-found > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.email-not-found > div > button {
  padding: 10px 15px !important;
  font-size: 15px !important;
  width: 100%;
}

.slider-main-div > div:first-child {
  height: 100vh !important;
}

.slider-main-div > div > div > div > div > div {
  height: 100%;
}

.slider-main-div {
  position: relative;
}

button.Mui-disabled {
  color: white !important;
  background-color: #5838fc70 !important;
  cursor: not-allowed !important;
  pointer-events: unset !important;
}

.no-spinner input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.no-spinner input[type="number"]::-webkit-outer-spin-button,
.no-spinner input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .slider-main-div > div:first-child {
    height: 100% !important;
  }
}
